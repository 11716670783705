import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/LOGO.png"
import navBg from "../images/Navbg.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../actions/authAction";

const Navbar = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [membership, setMembership] = useState([]);
    const [rebrand, setRebrand] = useState(rebrandData);

    const logout = () => {
        dispatch(logoutUser());
    }

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    useEffect(()=>{

        if(auth.user.membership !==''){
            setMembership(auth.user.membership.split('__'))
        }

    },[])

    return (
        <>
            <header>
                <nav className={`navbar px-lg-5 navbar-expand-lg navbar-me  ${props?.page === 'editor' ? '' : 'fixed-me'} navbar-light`} style={{ backgroundImage: `url(${navBg})` }}>
                    <div className="container">
                        <Link className="navbar-brand" to="/dashboard">
                            <img src={rebrand.data !== false ? rebrand.data.logo : logo} alt="logo" className="img-fluid " style={{width: "196px"}} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="text-white"><i className="fas fa-bars"></i></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/dashboard" activeClassName="active">Dashboard</NavLink>
                                </li>
                                {
                                    auth.user.is_client_account == "0" ?
                                        <>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/projects" activeClassName="active">Projects</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/integration">Integrations</NavLink>
                                            </li>
                                        </>
                                        : ''
                                }
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/help-and-support">Help</NavLink>
                                </li>
                            </ul>
                            {/* <div className="dropdown  belldropdown mr-4">
                                <button className="btn btn-secondary dropdown-toggle" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                    <img src={bellIcon} /> 3
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <Link className="dropdown-item" to="/">Yay!! Your scheduled post at 7/17/21, 10:30 AM
                                        has been published</Link>
                                    <Link className="dropdown-item" to="/">Yay!! Your scheduled post at 7/17/21, 10:30 AM
                                        has been published</Link>
                                    <Link className="dropdown-item" to="/">Yay!! Your scheduled post at 7/17/21, 10:30 AM
                                        has been published</Link>
                                </div>
                            </div> */}


                            <div className="dropdown show">
                                <button className="dropdown-toggle navmenuid " type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <span><img src={auth.user?.image} alt="user-img" width="40px" height="45px" /></span>
                                    <div className="text">
                                        <h6 className="m-0">Welcome</h6>{auth.user?.name}
                                    </div>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                        auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                            <>
                                                <Link className="dropdown-item" to="/profile">
                                                    <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                                </Link>
                                                <Link className="dropdown-item" to="/privacy">
                                                    <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                                </Link>
                                            </>
                                            : ''
                                    }

                                    {
                                        membership.includes('agency') ?
                                            auth.user.is_reseller === "0" && auth.user.is_client_account === "0" ?
                                                <>
                                                    <Link className="dropdown-item" to="account-management">
                                                        <span className="mr-2"><i className="fas fa-users" style={{ fontSize: "14px" }} ></i></span>
                                                        Account Management
                                                    </Link>
                                                    {/*<Link className="dropdown-item" to="/reseller">*/}
                                                    {/*    <span className="mr-2"><i className="fas fa-users" style={{ fontSize: "14px" }}></i></span>*/}
                                                    {/*    Reseller Accounts*/}
                                                    {/*</Link>*/}
                                                </>
                                                : ''
                                            : ''
                                    }
                                    {
                                        auth.user.is_client_account == "0" ?
                                            <>
                                                {
                                                    membership.includes('unlimited') || membership.includes('unlimitedlite') ?
                                                        <Link className="dropdown-item" to="/reel-merge">
                                                            <span className="mr-2"><i className="far fa-copy"></i></span> Reel Merge
                                                        </Link>
                                                    : ''
                                                }
                                                {
                                                    membership.includes('unlimited') || membership.includes('agency') ?
                                                        <Link className="dropdown-item" to="/upgrades">
                                                            <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                                        </Link>
                                                    : ''
                                                }
                                            </>
                                            : ''
                                    }
                                    <Link className="dropdown-item" to="/help-and-support">
                                        <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                    </Link>
                                    <span className="dropdown-divider"></span>
                                    <Link className="dropdown-item" onClick={(e) => logout()}>
                                        <span className="mr-2">
                                            <i className="fas fa-sign-out-alt"></i>
                                        </span> Sign Out
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}


export default React.memo(Navbar);