import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import Alert from "../Alert";
import { setAlert } from "../../actions/alert";

const AddAccount = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team_member',
        email: '',
        name: '',
        password: ''
    });
    const [passwordError, setPasswordError] = useState('');

    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        if (e.target.name === 'password') {
            validatePassword(e.target.value);
        }
    }

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /[0-9]/.test(password);
        const hasSpecialChars = /[!@#$%^&*]/.test(password);

        if (password.length < minLength || !hasUpperCase || !hasLowerCase || !hasNumbers || !hasSpecialChars) {
            setPasswordError('Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.');
        } else {
            setPasswordError('');
        }
    }

    const fetchClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTeamData(res.data.data.team_members);
                setClientData(res.data.data.clients);
            }
        }).catch(error => {
            // Handle error
        });
    }

    const addClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}create-client`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchClientTeam();
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
        });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (passwordError) return; // Prevent submission if there's a password error

        setLoader(true);
        addClientTeam();
    }

    useEffect(() => {
        fetchClientTeam();
    }, []);

    return (
        <>
            <Alert />
            <div className="row mt-5">
                <div className="col-md-12 text-white ">
                    <h5>Create Account</h5>
                </div>
            </div>
            <form className="add-account-box mt-2" method="post" onSubmit={onFormSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Account Type</small>
                            <select className="form-control" name="type" onChange={onInputChange}>
                                <option style={{ backgroundColor: "#000" }} value="team_member">Team Member</option>
                                <option style={{ backgroundColor: "#000" }} value="client">Client Account</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Account Name</small>
                            <input type="text" className="form-control"
                                placeholder="Enter Account Name" required name="name"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Account Email</small>
                            <input type="email" className="form-control" placeholder="Enter Email"
                                required name="email"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Account Password</small>
                            <input type="password" className="form-control"
                                placeholder="********" required name="password"
                                onChange={onInputChange}
                            />
                            {passwordError && <div className="text-danger">{passwordError}</div>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                        <div className="add-account-save-btn">
                            <button type="submit" className="btn-change7" disabled={loader || passwordError}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                            </button>
                        </div>
                    </div>
                </div>

            </form>

            <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} />
            <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} />
        </>
    )
}

export default AddAccount;
