import {combineReducers} from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import rebrandReducer from "./rebrandReducer";
import socialReducer from "./socialReducer";
import videoReducer from "./videoReducer";
import scriptReducer from "./scriptReducer";
import campaignReducer from "./campaignReducer";
import mergeVideos from "./mergeVideos";

const appReducer = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    support: supportReducer,
    rebrand: rebrandReducer,
    social: socialReducer,
    videos: videoReducer,
    script: scriptReducer,
    campaigns: campaignReducer,
    merge: mergeVideos
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;