import React, {useEffect} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import {Helmet} from "react-helmet";
import Webinar from "./Webinar";
import {useDispatch, useSelector} from "react-redux";
import {removeScriptData} from "../../actions/scriptAction";
import {removeScrapImage} from "../../actions/videoAction";


const Dashboard = () => {

    const dispatch = useDispatch();
    const brandName = useSelector(state => state.rebrand.data);

    useEffect(()=>{
        dispatch(removeScriptData());   // set initial state for script data
        dispatch(removeScrapImage());   // set initial state for script data
    },[])

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'ClipsReel'} | Dashboard </title>
            </Helmet>
            <Navbar/>
            <DashboardHead/>
            <DashboardContent/>
            <DashboardAbout/>
            <Webinar/>
            <Footer/>
        </>
    )
}

export default Dashboard;