import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TrainingTitles from "./TrainingTitles";
import queryString from "query-string";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import TrainingBreadcrumbs from "./TrainingBreadcrumbs";

const TrainingArticles = ({location}) => {

    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.support.articles);
    const brandName = useSelector(state => state.rebrand.data);

    const filterArticle = articleData.filter(article => article.id === id)[0];

    const filterName = filterArticle.items.filter((id) => id.id == art_id)[0];


    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'ClipsReel'} | Help & Support</title>
            </Helmet>
            <Navbar/>

            <section className="traning-sec">
                <div className="container">
                    <div className="row">
                        <TrainingBreadcrumbs location={location} name={filterName} />
                    </div>
                    <div className="row">
                        <TrainingTitles location={location} name={filterName}/>

                        <div className="col-md-8" style={{margin: "0px 0px 60px 0px"}}>
                            <div className="popular-topics-content">

                                <div className="tab-content mt-4" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                        {
                                            filterArticle.items.length > 0 ?
                                                filterArticle.items.map((item,index)=>{
                                                    return(
                                                        item.id == art_id ?
                                                        <>
                                                            <div key={item.id} className="text-white"
                                                                 dangerouslySetInnerHTML={{
                                                                     __html: item.content
                                                                 }}>
                                                            </div>
                                                        </>
                                                        : ''
                                                    )
                                                })
                                            : ''
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default TrainingArticles;