import React, {useEffect, useState} from "react";
import {generateSlideData} from "../../actions/videoAction";
import {useDispatch} from "react-redux";
import StoryBoardText from "./StoryBoardText";

const StoryBord = ({storyBoard, slideLength, template_id}) => {

    const dispatch = useDispatch();
    const [storyData, setStoryData] = useState(storyBoard.data.storyboard);

    // const [slide, setSlide] = useState(videos.data.slides);
    const [loadSlide,setLoadSlide] = useState(false);

    const generateNewSlides = async (item,index ) => {
        if(item.status === false){

            let randomKey = Math.floor(Math.random() * (7 - 1)) + 1;

            await dispatch(generateSlideData(item.text, index, template_id, setLoadSlide , false, randomKey));
        }
    }
    //
    useEffect(()=>{
        setStoryData(storyBoard.data.storyboard);
    },[storyBoard]);

    return(
        <>
            <div className="story-part-sec pt-2" style={{marginBottom: "50px"}}>
                <div className="row">
                    {
                        storyData.length > 0 ?
                            storyData.map((item,index)=>{
                                return(
                                    <div key={index} className="col-md-11 mx-auto mb-4">
                                        <StoryBoardText item={item} index={index} generateNewSlides={generateNewSlides}
                                                        slideLength={slideLength}
                                        />
                                    </div>
                                )
                            })
                        : ''
                    }
                </div>
            </div>
        </>
    )
}

export default React.memo(StoryBord);