import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import WebFont from "webfontloader";
import { useDispatch, useSelector } from "react-redux";
import {
    removeFontBgColor,
    updateFontBgColor,
    updateFontColor,
    updateFontFamily,
    updateFontSize, updateTextAlign,
    updateTextBold,
    updateTextItalic, updateTextLineHeight,
    updateTextLower,
    updateTextStrike,
    updateTextUnderline,
    updateTextUpper,
    updateSlideText, replaceWord, removeSpanBg, updateTranslate, applyToAllSlide
} from "../../actions/videoAction";
import hexRgb from "hex-rgb";
import { SketchPicker } from "react-color";
import rgbHex from "rgb-hex";
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import window from "../../global/window"
import { renderToStaticMarkup } from "react-dom/server";

const TextStyles = ({ selectedLayerData, selectedIndex, tilesData }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const selectedLayer = useMemo(() => {
        return selectedLayerData
    }, [selectedLayerData])


    const [fonts, setFonts] = useState([]);
    const [googleFonts, setGoogleFonts] = useState({ data: [] });
    const [rawText, setRawText] = useState(selectedLayer.translate_text);

    /*color*/
    const [showTextColor, setShowTextColor] = useState(false);
    const [showBgColor, setShowBgColor] = useState(false);

    const [lastColor, setLastColor] = useState(false);
    const [lastBg, setLastBg] = useState(false);

    const [slideAll, setSlideAll] = useState(false);

    const applyChanges = () => {
        if (slideAll === true) {
            setSlideAll(false);
            dispatch(applyToAllSlide('add'));
        } else {
            setSlideAll(true);
            dispatch(applyToAllSlide('remove'));
        }
    }

    const onInputChange = (e, type) => {
        setSlideAll(false);

        if (type === 'size') {
            dispatch(updateFontSize(e.target.value, selectedIndex));
        } else if (type === 'font_family') {
            dispatch(updateFontFamily(e.target.value, selectedIndex));
        }

    }

    const onInputColor = (e) => {
        setSlideAll(false);

        let rgbaColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        console.log(rgbaColor);

        dispatch(updateFontColor(rgbaColor, selectedIndex));
    }

    const onInputBgColor = (e) => {
        let rgbaColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        setSlideAll(false);
        dispatch(updateFontBgColor(rgbaColor, selectedIndex));
    }


    const onChangeColor = (color) => {
        setSlideAll(false);
        let hex = '#' + rgbHex(color);

        const options = {
            replace: ({ attribs, children }) => {
                if (!attribs) {
                    return;
                }

                if (attribs.id === `highlight-${selectedIndex}`) {
                    return (
                        <span id={`highlight-${selectedIndex}`} style={{ color: `${hex}`, backgroundColor: `${lastBg}` }}>
                            {domToReact(children, options)}
                        </span>
                    );
                }
            }
        }

        if (window.getSelection().toString() !== '') {
            setLastColor(hex);

            dispatch(
                replaceWord(`<span id="highlight-${selectedIndex}" style="color: ${hex}; background-color: ${false}">${window.getSelection().toString()}</span>`, window.getSelection().toString(), selectedIndex)
            )
        } else {
            dispatch(updateFontColor(hex, selectedIndex));
        }

    }

    const getHtmlContent = (htmlContent) => {
        const html = htmlContent;

        const options = {
            replace: ({ attribs, children }) => {

                console.log(attribs);
                if (!attribs) {
                    return;
                }

                if (attribs.id == `highlight-${selectedIndex}`) {
                    return (
                        <span style={{ color: 'hotpink' }}>
                            {domToReact(children, options)}
                        </span>
                    );
                }
            }
        };

        return parse(html, options);
    }

    const onChangeBgColor = (color) => {
        setSlideAll(false);
        let hex = '#' + rgbHex(color);
        setLastBg(hex);

        const options = {
            replace: ({ attribs, children }) => {
                if (!attribs) {
                    return;
                }

                if (attribs.id === `highlight-${selectedIndex}`) {
                    return (
                        <span id={selectedLayer.id} style={{ color: `${lastColor}`, backgroundColor: `${hex}` }}>
                            {domToReact(children, options)}
                        </span>
                    );
                }
            }
        }

        if (window.getSelection().toString() !== '') {

            let htmlNode = document.getElementById(`${selectedLayer.id}`);


            let html;
            if (htmlNode.childNodes.length > 0 && htmlNode !== null) {
                html = htmlNode.children;
                console.log('has node');
            } else {
                html = 0;
            }

            if (html.length < 0) {

                let parHtml = parse(selectedLayerData.text, options);

                dispatch(updateSlideText(renderToStaticMarkup(parHtml), selectedIndex))

            } else {
                dispatch(
                    replaceWord(`<span id="highlight-${selectedIndex}" style="color: ${false}; background-color: ${hex}">${window.getSelection().toString()}</span>`,
                        window.getSelection().toString(), selectedIndex)
                )
            }
        } else {
            dispatch(updateFontBgColor(hex, selectedIndex));
        }

    }

    const removeBgColor = () => {
        setSlideAll(false);

        const options = {

            replace: ({ attribs, children }) => {
                if (!attribs) {
                    return;
                }

                let props = attributesToProps(attribs);

                if (domToReact(children, options) == window.getSelection().toString()) {
                    props.style.backgroundColor = 'transparent'
                }

                if (attribs.id === `highlight-${selectedIndex}`) {
                    return (
                        <span {...props} className="remove-span">
                            {domToReact(children, options)}
                        </span>
                    );
                }
            }
        }

        if (window.getSelection().toString() !== '') {
            setLastBg(false)

            let htmlNode = document.getElementById(`${selectedLayer.id}`);

            let html;
            if (htmlNode.childNodes.length > 0) {
                html = htmlNode.children;
            } else {
                html = 0;
            }


            if (html.length > 0) {
                let parHtml = parse(selectedLayerData.text, options);

                dispatch(updateSlideText(renderToStaticMarkup(parHtml), selectedIndex))
                // dispatch(removeSpanBg(selectedIndex))

                // dispatch(
                //     replaceWord(`<span id="highlight-${selectedIndex}" style="color: ${html.style.color}; background-color: ">${html.innerHTML}</span>`,
                //         html.innerHTML, selectedIndex )
                // )
            }
        } else {
            dispatch(removeFontBgColor(selectedIndex));
        }
    }

    const onTextAlign = (type) => {
        setSlideAll(false);

        dispatch(updateTextAlign(type, selectedIndex));
    }

    const onChangeLineHeight = (e) => {
        setSlideAll(false);

        dispatch(updateTextLineHeight(e.target.value, selectedIndex))
    }

    const onInputSlideText = (e) => {

        let newText = e.target.value.split("\n");
        // setRawText(newText);

        dispatch(updateSlideText(newText, selectedIndex));
    }

    const onTextStyle = (type) => {
        setSlideAll(false);

        if (type === 'bold') {
            if (selectedLayer.bold === true) {
                dispatch(updateTextBold(false, selectedIndex));
            } else {
                dispatch(updateTextBold(true, selectedIndex));
            }
        } else if (type === 'italic') {
            if (selectedLayer.italic === true) {
                dispatch(updateTextItalic(false, selectedIndex));
            } else {
                dispatch(updateTextItalic(true, selectedIndex));
            }
        } else if (type === 'uppercase') {
            if (selectedLayer.uppercase === true) {
                dispatch(updateTextUpper(false, selectedIndex));
            } else {
                dispatch(updateTextUpper(true, selectedIndex));
            }
        } else if (type === 'lowercase') {
            if (selectedLayer.lowercase === true) {
                dispatch(updateTextLower(false, selectedIndex));
            } else {
                dispatch(updateTextLower(true, selectedIndex));
            }
        } else if (type === 'underline') {
            if (selectedLayer.underline === true) {
                dispatch(updateTextUnderline(false, selectedIndex));
            } else {
                dispatch(updateTextUnderline(true, selectedIndex));
            }
        } else if (type === 'strike') {
            if (selectedLayer.strike === true) {
                dispatch(updateTextStrike(false, selectedIndex));
            } else {
                dispatch(updateTextStrike(true, selectedIndex));
            }
        }

    }


    useEffect(() => {
        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/fetch-fonts',
            data: {},
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            if (res.data.status === true) {
                setFonts(res.data.data);

                let arr = res.data.data.map((item, index) => {
                    return (
                        item.value
                    )
                })

                setGoogleFonts({ ...googleFonts, data: arr });
            }
        }).catch(err => {

        })
    }, [])

    useEffect(() => {
        if (googleFonts.data.length > 0) {
            WebFont.load({
                google: {
                    families: googleFonts.data
                }
            });
        }
    }, [googleFonts.data])




    return (
        <>
            <div className="editor-text-customization" style={{ paddingBottom: "50px" }}>

                <div className="editor-wrapper editor-text-wrapper  editor-font-color mt-4">
                    <div
                        className="editor-font-family-size-wrapper d-flex flex-wrap justify-content-between align-content-center">
                        <span
                            className="label text-white col-12 p-0 mb-3"
                            style={{ fontFamily: "Poppins", fontWeight: 500 }}
                        >
                            Font Style
                        </span>
                        <div className="editor-text-family">
                            <select
                                id="sel-font"
                                className="form-control editor-input-dark edit-input-height"
                                name="font_family"
                                onChange={(e) => onInputChange(e, 'font_family')}
                            >
                                <option disabled selected>Select Font</option>
                                {
                                    fonts.length > 0 ?
                                        fonts.map((font, index) => {
                                            return (
                                                <option style={{ fontFamily: font.value }}
                                                    selected={font.value === selectedLayer.font_family}
                                                    value={font.value} key={index}>{font.name}</option>
                                            )
                                        })
                                        : ''
                                }
                            </select>
                        </div>
                        <div className="editor-text-weight-size d-flex flex-xl-nowrap flex-wrap">
                            <div className="input-wrapper w-100">
                                <input
                                    id="f-size"
                                    type="number"
                                    className="form-control editor-input-dark edit-input-height"
                                    name="f-size"
                                    min="10"
                                    value={`${selectedLayer.font_size}`}
                                    onChange={(e) => onInputChange(e, 'size')}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="editor-text-style editor-text-alignment mt-2 d-flex flex-xl-nowrap flex-wrap justify-content-between">
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.bold === true ? 'active' : ''} `}
                            data-toggle="tooltip"
                            title="Text Bold"
                            onClick={() => onTextStyle('bold')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-bold"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M0,0c33.3,0,66.7-0.1,100,0.1c0,8.2-0.4,16.4-1.6,24.5c-1.2-0.3-3.5-0.8-4.6-1.1c-1-3.1-2-6.3-3-9.4 c-8.2-2-16.7-2.2-24.1,2.4c0,25.2,0.2,50.4-0.2,75.6c4.7,1.4,11.8,0.9,12.3,7.4c-19,0.9-38.1,0.3-57.2,0.3 c-0.5-7.1,7.1-6.2,11.8-7.6c0.1-25.2-0.1-50.4,0-75.7c-7.3-4.4-15.7-4.4-23.8-2.6C8.3,18.6,6,22.9,2.1,26C0,17.6,0,8.8,0,0z"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.italic === true ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Text Italic"
                            onClick={() => onTextStyle('italic')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-italic"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 109.1"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M5.6,0C37,0,68.5,0,100,0c-2.3,8.8-5.1,17.5-8.4,26c-1.5,0.1-4.5,0.2-6,0.2c0.1-3.6,0.1-7.2,0.2-10.8 C76,12.9,65.9,13.3,57,18.2c-7.5,27.6-15.4,55.1-23,82.7c4.1,0.7,8.1,1.3,12.2,1.9c-0.4,1.4-1.3,4.3-1.8,5.7 c-14.7,1.3-29.4,0.3-44.1,0.3c0.5-1.5,1.6-4.6,2.1-6.2c4.8-1.2,13,0.9,14.1-5.9C23.4,70.4,30.9,44.2,38.3,18 c-10.1-6.8-29.6-6.9-32.5,7.8C4.3,26,1.4,26.4,0,26.6C0.6,17.5,2.9,8.7,5.6,0z"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.uppercase === true ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Text Uppercase"
                            onClick={() => onTextStyle('uppercase')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-upper"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 54.5"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M0,0c15.2,0,30.3-0.1,45.5,0c0,4.4-0.2,8.7-0.7,13.1c-0.7,0-2.1,0-2.8,0c-0.4-1.7-0.8-3.4-1.2-5.1 C36.2,6.5,31.4,6.5,27.3,9c0,13.7,0.2,27.5-0.1,41.2c2.5,0.9,6.5,0.5,6.7,4.1c-7.4,0.3-14.7,0.3-22.1,0.1c-0.1-3.8,4-3.3,6.6-4.2 c-0.1-13.7-0.1-27.4,0-41.1C14.2,6.5,9.4,6.5,4.8,7.7C4.4,9.4,4,11.1,3.5,12.8c-0.7,0.1-2.2,0.3-2.9,0.4C0.2,8.9,0,4.4,0,0z"
                                    />
                                    <path
                                        fill="currentColor"
                                        d="M54.5,0C69.7,0,84.9,0,100,0c0,4.4-0.2,8.7-0.7,13.1c-0.7,0-2.1,0-2.8,0c-0.4-1.7-0.8-3.4-1.2-5.1C90.8,6.5,86,6.5,81.8,9 c0,13.7,0.2,27.5-0.1,41.2c2.5,0.9,6.5,0.5,6.7,4.1c-7.3,0.3-14.7,0.3-22,0.1c-0.4-3.9,3.6-3.3,6.3-3.9c0.4-13.8,0-27.7,0.2-41.5 c-4.1-2.5-8.9-2.5-13.4-1.3c-0.5,1.7-0.9,3.4-1.3,5.1c-0.7,0.1-2.2,0.3-2.9,0.4C54.7,8.9,54.5,4.4,54.5,0z"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.lowercase === true ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Text Lowercase"
                            onClick={() => onTextStyle('lowercase')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-lower"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 133.4 100"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M0,0c27.8,0,55.6-0.1,83.4,0.1c-0.1,8-0.4,16-1.3,24c-1.2,0-3.8-0.1-5.1-0.1c-0.8-3.2-1.5-6.3-2.3-9.5 C66.4,12,57.6,11.9,50,16.5c0.1,25.2,0.3,50.4-0.2,75.5c4.7,1.4,11.9,0.9,12.3,7.5c-13.5,0.6-27,0.5-40.5,0.1 c-0.1-6.9,7.3-6.2,12.1-7.7c-0.3-25.2-0.2-50.3-0.1-75.5C26,12,17.3,12,8.9,14.1c-0.8,3.1-1.6,6.3-2.5,9.4 c-1.3,0.2-3.9,0.6-5.2,0.9C0.3,16.3,0,8.2,0,0z"
                                    />
                                    <path
                                        fill="currentColor"
                                        d="M91.7,50c13.9,0,27.8-0.1,41.7,0.1c0,3.8,0,7.6,0,11.4c-4.8-4.2-10.6-6.7-16.7-3.7c0.2,11.2,0,22.4,0.1,33.6 c-0.8,4.4,3.9,6.1,6.5,8.6c-7.1,0-14.2,0-21.3,0c2.5-2.5,7.3-4.2,6.5-8.6c0.1-11.2-0.2-22.4,0.1-33.6c-6.2-2.9-12.1-0.5-16.7,3.9 C91.7,57.8,91.7,53.9,91.7,50z"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.underline === true ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Text Underline"
                            onClick={() => onTextStyle('underline')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-underline"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 83.4 100"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M0,0.1c27.8,0,55.6-0.2,83.4,0.1c0,8.1-0.4,16.1-1.5,24.1c-1.2-0.1-3.5-0.4-4.7-0.5c-0.9-3.1-1.9-6.2-2.8-9.4 C66.2,12,57.4,11.9,50,16.6c0.1,19.6,0.1,39.2-0.2,58.8c4.7,1.6,12.1,0.8,12.2,7.5c-13.4,0.6-26.8,0.5-40.3,0.2 c-0.7-7.1,6.8-6.1,11.5-7.4c0.5-19.7-0.1-39.4,0.2-59.1C26,12,17.5,12.1,9.3,14c-1,3.1-2,6.2-3,9.3c-1.2,0.3-3.6,1-4.8,1.4 C0.2,16.6,0,8.3,0,0.1z"
                                    />
                                    <path
                                        fill="currentColor"
                                        d="M0.1,91.7c27.8-0.1,55.5-0.1,83.3,0c0,2.1,0,6.2,0,8.3c-27.8,0.1-55.5,0.1-83.3,0C0.1,97.9,0.1,93.7,0.1,91.7z"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.strike === true ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Text Strikethrough"
                            onClick={() => onTextStyle('strike')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-strike"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 83.3 100"
                                    xmlSpace="preserve"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M-0.1,0.1c27.8,0,55.6-0.2,83.4,0.1c-0.1,8-0.5,16-1.4,24c-1.2-0.1-3.7-0.2-4.9-0.3c-0.8-3.2-1.7-6.3-2.6-9.5 c-8.2-2.4-17-2.5-24.5,2.2c0.2,11.2,0,22.3,0.1,33.4C44.4,50,38.9,50,33.3,50c0.1-11.1-0.1-22.3,0.1-33.4C25.9,12,17.3,12.1,9,14.1 c-0.9,3.1-1.9,6.3-2.7,9.4c-1.2,0.3-3.7,0.8-5,1.1C0.2,16.4-0.1,8.2-0.1,0.1z"
                                    />
                                    <path
                                        fill="currentColor"
                                        d="M0,58.3c27.8,0,55.5-0.1,83.3,0c0,2.1,0,6.2,0,8.3c-11.1-0.1-22.2,0.1-33.3,0c0,8.5-0.2,17-0.4,25.4 c4.7,1.5,12.1,0.8,12.2,7.5c-13.4,0.6-26.9,0.5-40.3,0.2c-0.7-7.2,6.7-6.1,11.5-7.3c0.4-8.6,0.3-17.2,0.2-25.8 c-11.1,0.1-22.2,0-33.3,0C0,64.6,0,60.4,0,58.3z"
                                    />
                                </svg>
                            </svg>
                        </button>
                    </div>
                    <div
                        className="editor-text-align editor-text-alignment mt-2 d-flex flex-xl-nowrap flex-wrap justify-content-between">
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.align === 'left' ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Align Text Left"
                            onClick={() => onTextAlign('left')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-left"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 157.3 180"
                                    xmlSpace="preserve"
                                >
                                    <rect
                                        fill="currentColor"
                                        x="-0.1"
                                        width="157.4"
                                        height="20.3"
                                    />
                                    <rect
                                        fill="currentColor"
                                        x="-0.1"
                                        y="39.8"
                                        width="77.5"
                                        height="20.7"
                                    />
                                    <rect
                                        fill="currentColor"
                                        x="-0.1"
                                        y="79.8"
                                        width="157.3"
                                        height="20.4"
                                    />
                                    <rect
                                        fill="currentColor"
                                        y="119.6"
                                        width="77.4"
                                        height="20.7"
                                    />
                                    <rect
                                        fill="currentColor"
                                        x="-0.1"
                                        y="159.6"
                                        width="157.4"
                                        height="20.4"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.align === 'center' ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Align Text Center"
                            onClick={() => onTextAlign('center')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-center"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 157.3 180"
                                    xmlSpace="preserve"
                                >
                                    <rect fill="currentColor" width="157.3" height="20.4" />
                                    <polygon
                                        fill="currentColor"
                                        points="39.9,39.8 117.4,39.8 117.4,60.5 39.9,60.5  "
                                    />
                                    <rect
                                        fill="currentColor"
                                        y="79.8"
                                        width="157.3"
                                        height="20.5"
                                    />
                                    <polygon
                                        fill="currentColor"
                                        points="39.9,119.6 117.4,119.6 117.4,140.3 39.9,140.3  "
                                    />
                                    <rect
                                        fill="currentColor"
                                        y="159.6"
                                        width="157.3"
                                        height="20.4"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.align === 'right' ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Align Text Right"
                            onClick={() => onTextAlign('right')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-right"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 159.6 180"
                                    xmlSpace="preserve"
                                >
                                    <rect fill="currentColor" width="159.5" height="20.3" />
                                    <rect
                                        fill="currentColor"
                                        x={82}
                                        y="39.8"
                                        width="77.6"
                                        height="20.6"
                                    />
                                    <rect
                                        fill="currentColor"
                                        x="2.1"
                                        y="79.8"
                                        width="157.4"
                                        height="20.1"
                                    />
                                    <rect
                                        fill="currentColor"
                                        x="82.2"
                                        y="119.6"
                                        width="77.4"
                                        height="20.6"
                                    />
                                    <rect
                                        fill="currentColor"
                                        y="159.7"
                                        width="159.6"
                                        height="20.3"
                                    />
                                </svg>
                            </svg>
                        </button>
                        <button
                            className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedLayer.align === 'justify' ? 'active' : ''}`}
                            data-toggle="tooltip"
                            title="Text Align Justify"
                            onClick={() => onTextAlign('justify')}
                        >
                            <svg width="22px" height="12px" viewBox="0 0 22 12">
                                <svg
                                    id="ico-text-justify"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 161.1 179.9"
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <rect
                                            fill="currentColor"
                                            width="161.1"
                                            height="20.3"
                                        />
                                        <rect
                                            fill="currentColor"
                                            y={40}
                                            width="161.1"
                                            height="20.3"
                                        />
                                        <rect
                                            fill="currentColor"
                                            y="79.9"
                                            width="161.1"
                                            height="20.3"
                                        />
                                        <rect
                                            fill="currentColor"
                                            x="-0.1"
                                            y="119.7"
                                            width="161.2"
                                            height="20.3"
                                        />
                                        <rect
                                            fill="currentColor"
                                            x="-0.1"
                                            y="159.7"
                                            width="161.2"
                                            height="20.3"
                                        />
                                    </g>
                                </svg>
                            </svg>
                        </button>
                        <div className="input-group-prepend">
                            <span className="icon">
                                <svg width="14px" height="15px" viewBox="0 0 14 15">
                                    <svg
                                        id="ico-lineheight"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 139.9 150"
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M83.2,0c4.4,0,8.7,0,13.1,0c7.8,20,15.6,39.9,23.4,59.9c6.6,0,13.3,0,19.9,0c0.1,2.5,0.2,7.6,0.3,10.2 c-33.4,0-66.7,0-100.1,0c0-2.6,0-7.6,0-10.2c6.7,0,13.4,0,20.1,0C67.6,39.9,75.4,20,83.2,0 M88.7,14c-3.8,8.1-6.5,16.7-9.6,25.1 c7.1,0,14.1,0,21.2,0c-3.1-8.5-5.8-17-9.6-25.2C90.3,14,89.2,14,88.7,14 M76.1,48.4c-1.5,3.9-3,7.8-4.4,11.6c12.1,0,24.1,0,36.1,0 c-1.4-3.9-2.9-7.8-4.4-11.6C94.3,48.4,85.2,48.4,76.1,48.4z"
                                        />
                                        <path
                                            fill="currentColor"
                                            d="M21.5,70.1c1.7,0,4.9,0,6.6,0c5.6,8.2,13.9,13.8,21.6,19.8c-6.6,0.1-13.2,0.2-19.8,0.2c0,9.9,0,19.8,0,29.7 c6.6,0.1,13.2,0.1,19.8,0.2c-8.3,6.8-16.7,13.4-25.2,19.9C16.4,133.2,8.2,126.6,0,120c6.6,0,13.2-0.1,19.8-0.1c0-9.9,0-19.9,0-29.8 C13.2,90,6.7,90,0.1,89.9C7.8,83.9,16,78.3,21.5,70.1z"
                                        />
                                        <path
                                            fill="currentColor"
                                            d="M83.2,79.9c4.4,0,8.7,0,13.1,0c7.8,20,15.6,39.9,23.4,59.9c6.7,0.1,13.4,0.2,20.1,0.2c0,2.5,0,7.5,0,10 c-33.4,0-66.7,0-100,0c0-2.5,0-7.6,0-10.1c6.7,0,13.4-0.1,20.1-0.1C67.6,119.8,75.4,99.8,83.2,79.9 M79.1,119c7.1,0,14.1,0,21.1,0 c-3.5-8.3-5.1-18.6-10.4-25.2C84.4,99.8,82.7,110.9,79.1,119 M76.1,128.3c-1.5,3.9-2.9,7.7-4.4,11.6c12,0,24,0,36.1,0 c-1.5-3.9-2.9-7.8-4.4-11.6C94.3,128.3,85.2,128.3,76.1,128.3z"
                                        />
                                    </svg>
                                </svg>
                            </span>
                            <input
                                id="t-lineheight"
                                className="form-control editor-input-dark text-white edit-input-height"
                                type="number"
                                name="Lineheight"
                                value={selectedLayer.line_height}
                                min="1"
                                step="0.1"
                                onChange={(e) => onChangeLineHeight(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="editor-wrapper editor-color-wrapper editor-font-color mt-4">
                    <span
                        className="label text-white"
                        style={{ fontFamily: "Poppins", fontWeight: 500 }}
                    >
                        Font Color
                    </span>
                    <div className="editor-text-color justify-content-between d-flex flex-wrap mt-3">
                        <div className="input-color text-center mb-2">
                            <button
                                id="t-color-btn"
                                style={{ background: `${selectedLayer.font_color.includes('#') ? `rgb(${hexRgb(selectedLayer.font_color).red}, ${hexRgb(selectedLayer.font_color).green}, ${hexRgb(selectedLayer.font_color).blue}) none repeat scroll 0% 0%` : `${selectedLayer.font_color}`}` }}
                                onClick={(e) => setShowTextColor(true)}
                            >
                            </button>
                            <input
                                id="tColor-hex"
                                className="editor-input-dark text-white form-control edit-input-height"
                                value={selectedLayer.font_color}
                                readOnly
                            />
                            <label>HEX</label>
                            {showTextColor ? <div>
                                <div className="swatch-cover" onClick={(e) => setShowTextColor(false)} />
                                <SketchPicker color={selectedLayer.font_color} onChange={(e) => onInputColor(e)} />
                            </div> : null}
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input
                                id="tColor-red"
                                className="form-control editor-input-dark edit-input-height"
                                type="text"
                                name="Red"
                                value={selectedLayer.font_color.includes('#') ? hexRgb(selectedLayer.font_color).red : ''}
                                readOnly
                            />
                            <label htmlFor="tColor-red">R</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input
                                id="tColor-grn"
                                className="form-control editor-input-dark edit-input-height"
                                type="text"
                                name="Green"
                                readOnly
                                value={selectedLayer.font_color.includes('#') ? hexRgb(selectedLayer.font_color).green : ''}
                            />
                            <label htmlFor="tColor-green">G</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center mr-0">
                            <input
                                id="tColor-blu"
                                className="form-control editor-input-dark edit-input-height"
                                type="text"
                                name="Blue"
                                readOnly
                                value={selectedLayer.font_color.includes('#') ? hexRgb(selectedLayer.font_color).blue : ''}
                            />
                            <label htmlFor="tColor-blue">B</label>
                        </div>
                        <div className="swatches w-100 d-flex flex-wrap">
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(255, 255, 255)" }}
                                onClick={() => onChangeColor("rgb(255, 255, 255)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(225, 225, 225)" }}
                                onClick={() => onChangeColor("rgb(255, 255, 255)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(215, 215, 215)" }}
                                onClick={() => onChangeColor("rgb(215, 215, 215)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(204, 204, 204)" }}
                                onClick={() => onChangeColor("rgb(204, 204, 204)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(194, 194, 194)" }}
                                onClick={() => onChangeColor("rgb(194, 194, 194)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(183, 183, 183)" }}
                                onClick={() => onChangeColor("rgb(183, 183, 183)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(161, 161, 161)" }}
                                onClick={() => onChangeColor("rgb(161, 161, 161)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(149, 149, 149)" }}
                                onClick={() => onChangeColor("rgb(149, 149, 149)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(232, 201, 255)" }}
                                onClick={() => onChangeColor("rgb(232, 201, 255)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(200, 126, 255)" }}
                                onClick={() => onChangeColor("rgb(200, 126, 255)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(243, 74, 255)" }}
                                onClick={() => onChangeColor("rgb(243, 74, 255)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(209, 157, 248)" }}
                                onClick={() => onChangeColor("rgb(209, 157, 248)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(155, 51, 233)" }}
                                onClick={() => onChangeColor("rgb(155, 51, 233)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(129, 51, 219)" }}
                                onClick={() => onChangeColor("rgb(129, 51, 219)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(112, 51, 213)" }}
                                onClick={() => onChangeColor("rgb(112, 51, 213)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(35, 128, 63)" }}
                                onClick={() => onChangeColor("rgb(35, 128, 63)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(84, 196, 35)" }}
                                onClick={() => onChangeColor("rgb(84, 196, 35)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(96, 104, 136)" }}
                                onClick={() => onChangeColor("rgb(96, 104, 136)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(124, 131, 172)" }}
                                onClick={() => onChangeColor("rgb(124, 131, 172)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(91, 105, 190)" }}
                                onClick={() => onChangeColor("rgb(91, 105, 190)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(48, 64, 155)" }}
                                onClick={() => onChangeColor("rgb(48, 64, 155)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch"
                                style={{ backgroundColor: "rgb(34, 60, 219)" }}
                                onClick={() => onChangeColor("rgb(34, 60, 219)")}
                            >
                                {" "}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="editor-wrapper editor-color-wrapper editor-font-color mt-4">
                    <span
                        className="label text-white"
                        style={{ fontFamily: "Poppins", fontWeight: 500 }}
                    >
                        Font Background
                    </span>
                    <div className="text-bg mb-2 justify-content-between d-flex flex-wrap mt-3">
                        <div className="input-color text-center mb-2">
                            <button id="t-bg-btn"
                                style={{ backgroundColor: `${selectedLayer.bg_color !== false ? selectedLayer.bg_color : '#fff'}` }}
                                onClick={() => setShowBgColor(true)}>
                            </button>
                            <input
                                id="tBg-hex"
                                readOnly
                                className="editor-input-dark text-white form-control edit-input-height"
                                value={selectedLayer.bg_color !== false ? selectedLayer.bg_color : ''}
                            />
                            <label>HEX</label>
                            {showBgColor ? <div>
                                <div className="swatch-cover" onClick={(e) => setShowBgColor(false)} />
                                <SketchPicker color={selectedLayer.bg_color !== false ? selectedLayer.bg_color : '#fff'}
                                    onChange={(e) => onInputBgColor(e)} />
                            </div> : null}
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input
                                id="tBg-red"
                                className="form-control editor-input-dark edit-input-height"
                                type="text"
                                name="Red"
                                readOnly
                                value={selectedLayer.bg_color !== false ? selectedLayer.bg_color.includes('#') ? hexRgb(selectedLayer.bg_color).red : `` : ''}
                            />
                            <label htmlFor="tBg-red">R</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input
                                id="tBg-grn"
                                className="form-control editor-input-dark edit-input-height"
                                type="text"
                                name="Green"
                                readOnly
                                value={selectedLayer.bg_color !== false ? selectedLayer.bg_color.includes('#') ? hexRgb(selectedLayer.bg_color).green : `` : ''}
                            />
                            <label htmlFor="tBg-green">G</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center mr-0">
                            <input
                                id="tBg-blu"
                                className="form-control editor-input-dark edit-input-height"
                                type="text"
                                name="Blue"
                                readOnly
                                value={selectedLayer.bg_color !== false ? selectedLayer.bg_color.includes('#') ? hexRgb(selectedLayer.bg_color).blue : `` : ''}
                            />
                            <label htmlFor="tBg-blue">B</label>
                        </div>
                        <div className="swatches w-100 d-flex flex-wrap">
                            <button
                                className="tBg no-color"
                                style={{ backgroundColor: "rgb(255, 255, 255)" }}
                                onClick={() => removeBgColor()}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(225, 225, 225)" }}
                                onClick={() => onChangeBgColor("rgb(225, 225, 225)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(215, 215, 215)" }}
                                onClick={() => onChangeBgColor("rgb(215, 215, 215)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(204, 204, 204)" }}
                                onClick={() => onChangeBgColor("rgb(204, 204, 204)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(194, 194, 194)" }}
                                onClick={() => onChangeBgColor("rgb(194, 194, 194)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(183, 183, 183)" }}
                                onClick={() => onChangeBgColor("rgb(183, 183, 183)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(161, 161, 161)" }}
                                onClick={() => onChangeBgColor("rgb(161, 161, 161)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(149, 149, 149)" }}
                                onClick={() => onChangeBgColor("rgb(149, 149, 149)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(125, 125, 125)" }}
                                onClick={() => onChangeBgColor("rgb(125, 125, 125)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(91, 91, 91)" }}
                                onClick={() => onChangeBgColor("rgb(91, 91, 91)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(65, 65, 65)" }}
                                onClick={() => onChangeBgColor("rgb(65, 65, 65)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(29, 169, 254)" }}
                                onClick={() => onChangeBgColor("rgb(29, 169, 254)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(173, 188, 218)" }}
                                onClick={() => onChangeBgColor("rgb(173, 188, 218)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(196, 173, 106)" }}
                                onClick={() => onChangeBgColor("rgb(196, 173, 106)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(89, 106, 149)" }}
                                onClick={() => onChangeBgColor("rgb(89, 106, 149)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(75, 201, 229)" }}
                                onClick={() => onChangeBgColor("rgb(75, 201, 229)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(245, 138, 109)" }}
                                onClick={() => onChangeBgColor("rgb(245, 138, 109)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(114, 196, 185)" }}
                                onClick={() => onChangeBgColor("rgb(114, 196, 185)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(203, 214, 236)" }}
                                onClick={() => onChangeBgColor("rgb(203, 214, 236)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(196, 146, 122)" }}
                                onClick={() => onChangeBgColor("rgb(196, 146, 122)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(251, 160, 117)" }}
                                onClick={() => onChangeBgColor("rgb(251, 160, 117)")}
                            >
                                {" "}
                            </button>
                            <button
                                className="tColor swatch mb-0"
                                style={{ backgroundColor: "rgb(216, 170, 44)" }}
                                onClick={() => onChangeBgColor("rgb(216, 170, 44)")}
                            >
                                {" "}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="dimmer-light-box mt-4">
                    <div className="row m-0">
                        <div className="col-md-12 mx-auto p-0">
                            <div className="dimmer-wrapper">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <div className="dimmer-light d-flex align-items-center">
                                            <h6 className="mb-0">Apply to All Slides</h6>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="dimmer-switch-btn">
                                            <div className="remove-logo-switch-btn ">
                                                <div className="form-check form-switch d-flex justify-content-end  p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckChecked"
                                                        value={slideAll}
                                                        checked={slideAll}
                                                        disabled={slideAll}
                                                        onChange={(e) => applyChanges()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextStyles