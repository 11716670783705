import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TempContent from "./TempContent";
import axios from "axios";
import { baseURL } from "../../global/global";
import { addScrapImage, addVideoData } from "../../actions/videoAction";
import { setAlert } from "../../actions/alert";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import reactImageSize from 'react-image-size';
import Alert from "../Alert";
import { removeCampStatus } from "../../actions/scriptAction";

const Templates = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const videoData = useSelector(state => state.videos)
    const scriptData = useSelector(state => state.script);
    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);
    const scrapImages = useSelector(state => state.videos.text);

    const [templates, setTemplates] = useState([]);
    const [tempLoader, setTempLoader] = useState(false);

    const [script, setScript] = useState({
        title: scriptData.data.title,
        text: scriptData.data.text,
        size: scriptData.dimension,
        template_id: false,
        type: scriptData.campaign_type,
        url: scriptData.url
    });

    const onInputTemplate = (id, temp_id) => {
        // setScript({...script, template_id: id});
        generateScriptData(id, temp_id);
    }

    const [loader, setLoader] = useState(false);

    const checkURLImage = (images) => {

        const random = Math.floor(Math.random() * images.length);
        return images[random];

    }

    const generateScriptData = async (id, temp_id) => {
        setLoader(true);
        script.template_id = id;
        script.template_no = temp_id;

        await axios({
            method: "POST",
            url: `${baseURL}create-campaign`,
            data: script,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {

                if (videoData.scrap_images.length > 0 && videoData.text !== false) {
                    let resData = checkURLImage(videoData.scrap_images)

                    res.data.data.poster = resData;
                    res.data.data.slides[0].file_url = resData;
                    res.data.data.slides[0].poster = resData;
                }

                dispatch(addVideoData(res.data.data));
                history.push(`editor?id=${res.data.data.id}`);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'danger'));
            setLoader(false);
        })
    }

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    useEffect(() => {
        setTempLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}templates`,
            data: { dimension: scriptData.dimension },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                let arrRand = res.data.data;
                // shuffle(arrRand);

                setTemplates(res.data.data);
            }
            setTempLoader(false);
        }).catch(err => {
            console.log(err);
            setTempLoader(false);
        })

    }, [])


    const get_url_extension = (url) => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    async function checkURL(url) {
        const { width, height } = await reactImageSize(url);

        return new Promise((resolve, reject) => {
            const imageType = get_url_extension(url);

            if (imageType === 'png' || imageType === 'jpg' || imageType === 'jpeg') {

                if (height > 100 && width > 100) {
                    resolve(true);
                }
                reject(false);
            }
            reject(false);
        });

    }

    const scrapImageFun = async () => {
        if (scrapImages) {
            let imgData = JSON.parse(scrapImages)

            for (let i = 0; i < imgData?.images?.length; i++) {
                await checkURL(imgData[i].url).then((res) => {
                    dispatch(addScrapImage(imgData[i].url))
                }).catch(err => {

                })

            }
        }

    }

    useEffect(() => {
        if (scrapImages !== false) {
            scrapImageFun()
        }

        return () => {
            dispatch(removeCampStatus());
        }
    }, [])

    return (
        <>
            <Alert />
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'ClipsReel'} | Templates</title>
            </Helmet>
            <Navbar />
            <section className="premium-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 d-flex justify-content-lg-start justify-content-center">
                            <div className="premium-template-box d-flex text-white align-items-center  ">
                                <div className="template-name ">
                                    <h4 className="m-0">Templates</h4>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-lg-end justify-content-center">

                        </div>
                    </div>
                </div>
            </section>

            <section className="template-body">
                <div className="container">
                    <div className="row">
                        {
                            tempLoader && templates.length === 0 ? <div className="loader-center text-center" style={{ fontSize: "25px", color: "#ffffff" }}><i className="fa fa-spinner fa-spin mr-2" /> Fetching Templates</div> : ''
                        }
                        {
                            templates.length > 0 ?
                                templates.map((template, index) => {
                                    return (
                                        <TempContent
                                            image={template.thumbnail}
                                            name={template.name}
                                            id={template.id}
                                            onInputTemplate={onInputTemplate}
                                            url={template.preview_video}
                                            template_id={script.template_id}
                                            template_no={template.template_no}
                                            key={index}
                                        />
                                    )
                                })
                                : ''
                        }
                    </div>


                    {/*<div className="text-right">*/}
                    {/*    <button type="button" className="btn-change7 btn-custom mt-4"*/}
                    {/*            onClick={(e)=>generateScriptData(e)}*/}
                    {/*            disabled={script.template_id===false ? true : false}*/}
                    {/*    >*/}
                    {/*        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : <i className="fas fa-pencil-alt"></i> } Create*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>


            </section>


            {
                loader ?
                    <div className="loader-sec">
                        <div className="loader">
                        </div>
                    </div>
                    : ''
            }

            <Footer />


        </>
    )
}

export default Templates;