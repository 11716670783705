import React, {useEffect} from "react";
import SocialNetwork from "./SocialNetwork";

import facebookIcon from "../../images/logo-facebook.svg";
import youtubeIcon from "../../images/YT-DEFAULT.png";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import sonorityIcon from "../../images/logo-sonority.png";

import facebookDisable from "../../images/FB_DISABLE.png";
import youtubeDisable from "../../images/YT_DISABLE.png";
import syvidDisable from "../../images/SYVIDED_DISABLE.png";
import liveDisable from "../../images/LIVEREEL_DISABLE.png";
import scriptDisable from "../../images/SCRIPT_DISABLE.png";
import sonorityDisable from "../../images/SONORITY_DISABLE.png";
import trimDisable from "../../images/TRIM_DISABLE.png";

import {useDispatch, useSelector} from "react-redux";
import {fetchSocialAccounts} from "../../actions/socialAction";

const IntegrationHead = ( ) => {

    const dispatch = useDispatch();

    const socialData = useSelector(state => state.social);

    useEffect(()=>{
        dispatch(fetchSocialAccounts());
    },[])

    return(
        <>
            <section className="integration-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="integration-head text-white text-center">
                                <h1>Integrations</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 mx-auto">
                            <div className="integration-box">
                                <ul className="list-inline d-flex  flex-wrap justify-content-center">
                                    <SocialNetwork icon={socialData.facebook.length>0 ? facebookIcon : facebookDisable} name="Facebook" />
                                    <SocialNetwork icon={socialData.youtube.length>0 ? youtubeIcon : youtubeDisable} name="YouTube" />
                                    <SocialNetwork icon={socialData.syvid.length>0 ? syvidIcon : syvidDisable} name="SyVid" />
                                    <SocialNetwork icon={socialData.scriptreel.length>0 ? scriptIcon : scriptDisable} name="ScriptReel" />
                                    <SocialNetwork icon={socialData.trimreel.length>0 ? trimIcon : trimDisable} name="TrimReel" />
                                    <SocialNetwork icon={socialData.livereel.length>0 ? liveIcon : liveDisable} name="LiveReel" />
                                    <SocialNetwork icon={socialData.sonority.length>0 ? sonorityIcon : sonorityDisable} name="Sonority" />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntegrationHead;